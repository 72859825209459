.nav {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 70px;
  background: hsla(0, 0%, 100%, .7);
  z-index: 3;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .5);
  transition: all 1s ease;
  @media (max-width: 1200px) {
    display: none;
  }
  &__logo {
    display: block;
    width: 50px;
    height: 50px;
    transition: transform .5s ease;
    &:hover {
      transform: rotate(360deg);
      opacity: .7;
    }
  }
  &__img {
    width: 50px;
    height: 50px;
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__link {
    font-size: 16px;
    color: $black;
    text-decoration: none;
    transition: all .5s ease;
    padding: 0px 16px;
    .fa-chevron-down {
      font-size: 14px;
      transition: transform .3s ease;
      &_active {
        transform: rotate(180deg);
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $white;
      color: $red;
      padding: 24px 16px;
      text-decoration: none;
    }
    &_active {
      color: $red;
      text-decoration: none;
    }
  }

  &_active {
    position: fixed;
    background: $white;
    transition: all 1s ease;
    .menu-dropdown {
      background: #fff;
      box-shadow: 2px 10px 8px rgba(0, 0, 0, .5);
      &__link {
        background: rgba(255, 255, 255, .5);
      }
    }
  }
}

.tel {
  display: flex;
  flex-direction: column;
  &__item {
    text-decoration: none;
    color: #333;
    transition: all .5s ease;
    &:first-child {
      margin-bottom: 3px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: .7;
      color: #333;
      text-decoration: none;
      i {
        transform: rotate(320deg);
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
        color: $black;
      }
    }
  }
  i {
    margin-right: 5px;
    font-size: 14px;
  }
}

.menu-dropdown {
  display: none;
  position: absolute;
  top: 47px;
  width: 250px;
  background: rgba(255, 255, 255, .7);
  box-shadow: 2px 10px 8px rgba(0, 0, 0, .5);
  &__link {
    display: block;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: all .5s ease;
    padding: 10px 20px;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $white;
      color: $red;
      padding: 10px 10px;
      text-decoration: none;
    }
  }
}

.hamburger {
  color: #000;
  position: fixed;
  left: 0;
  display: none;
  padding: 35px 25px;
  border: none;
  background: none;
  outline: none;
  height: 100px;
  z-index: 15;
  transition: all .3s ease-in-out;
  @media (max-width: 1200px) {
    display: flex;
  }
  &__item {
    background: #000;
    display: block;
    height: 3px;
    margin: 0 0 10px;
    transition: all 0.3s ease-in-out;
    width: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }

  &_active {
    .hamburger {
      &__item {
        margin: 0 0 5px;
        &:first-child {
          transform: rotate(45deg);
          transform-origin: 10px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          transform-origin: 8px;
        }
      }
    }
  }
}

.mob-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  transition: background .5s linear, .0s ease;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 120px 25px 25px;
    width: 305px;
    height: 100vh;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(38.0559px);
    transform: translateX(-305px);
    box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3);
    transition: all .5s ease;
    @media (max-width: 576px) {
      width: 200px;
    }
    @media (max-width: 320px) {
      padding: 80px 25px 25px;
    }
  }
  &__item {
    padding-bottom: 15px;
    @media (max-width: 576px) {
      padding-bottom: 5px;
    }
  }
  &__title {
    padding-left: 10px;
    font-size: 18px;
    letter-spacing: .7px;
    text-transform: uppercase;
  }
  .nav__logo {
    width: 100px;
    height: auto;
    margin: 0 auto;
    @media (max-width: 576px) {
      width: 80px; 
    }
  }
  .nav__img {
    width: 100%;
    height: 100%;
  }
  &__link {
    font-size: 18px;
    color: $black;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    outline: none;
    letter-spacing: .77px;
    transition: all .5s ease;
    &:hover {
      text-decoration: none;
      color: $red;
      opacity: .7;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  .btn {
    width: 100%;
    @media (max-width: 576px) {
      font-size: 10px;
    }
    &::after {
      left: 25px;
      @media (max-width: 992px) {
        left: 0;
      }
    }
  }
  .tel {
    &__item {
      padding-bottom: 12px;
      font-size: 18px;
      @media (max-width: 576px) {
        padding-bottom: 2px;
        font-size: 14px;
      }
    }
  }

  &_active {
    width: 100vw;
    background: rgba(0, 0, 0, .8);
    .mob-menu {
      &__content {
        transform: translateX(0px);
      }
    }
  }
}