header { 
  flex: 0 0 auto;
}
.header {
  position: relative;
  min-height: 100vh;
}

.header-slider {
  width: 100%;
  height: 100vh;
  &__slide {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #333;
      opacity: .8;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 820px;
    min-height: 100vh;
    @media (max-width: 960px) {
      max-width: 100%;
    }
  }
  &__title {
    font-family: 'acta-display', serif;
    font-weight: 400;
    font-size: 110px;
    letter-spacing: -1px;
    color: $red;
    line-height: 100px;
    margin: 20px 0 60px;
    @media (max-width: 960px) {
      font-size: 80px;
      line-height: 80px;
    }
    @media (max-width: 768px) {
      font-size: 60px;
      line-height: 60px;
    }
    @media (max-width: 480px) {
      margin: 20px 0 50px;
      font-size: 40px;
      line-height: 40px;
    }
  }
  &__subtitle {
    margin-top: 20px;
    font-family: 'acta-display', serif;
    font-weight: 400;
    font-size: 34px;
    color: $white;
    @media (max-width: 960px) {
      font-size: 24px;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
  &__meta {
    display: inline-block;
    position: relative;
    font-family: 'Arial', sans-serif;
    font-size: 13px;
    letter-spacing: 5px;
    color: #b3b3b3;
    text-transform: uppercase;
    @media (max-width: 480px) {
      font-size: 11px;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: auto;
      margin-left: 15px;
      transform: translateY(-50%);
      width: 45px;
      height: 1px;
      background-color: #b3b3b3;
    }
  }

  .slick {
    &-dots {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      top: 50%;
      right: 50px;
      transform: translateY(-50%);
      @media (max-width: 768px) {
        right: 30px;
      }
      @media (max-width: 576px) {
        flex-direction: row;
        top: unset;
        right: unset;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
      li {
        margin: 20px 0;
        width: 16px;
        button {
          position: relative;
          width: 16px;
          height: 16px;
          border-radius: 100%;
          &::before {
            content: '';
            width: 16px;
            height: 16px;
            background-color: $silver;
            border-radius: 100%;
            border: 1px solid $silver;
            opacity: .5;
            transition: all .5s ease;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border: 1px solid rgba(255, 255, 255, .5);
            border-radius: 100%;
            opacity: 0;
            transition: opacity 0.4s ease-in-out, width 0.4s ease-in-out, height 0.4s ease-in-out;
          }
          &:hover::before {
            opacity: 1;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: 576px) {
          margin: 0 20px;
        }
      }
      li.slick-active {
        button {
          &::before {
            border: 1px solid $white;
            background-color: $white;
            opacity: 1;
          }
          &::after {
            content: '';
            width: 300%;
            height: 300%;
            opacity: 1;
          }
        }
      }
    }
  }
}

.header-scroll {
  &__arrow-down {
    position: absolute;
    left: 50%;
    bottom: 5vh;
    display: block;
    text-align: center;
    font-size: 20px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 13px;
    height: 13px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: 9;
    -webkit-transform: translate(-50%, 0%) rotate(45deg);
    -moz-transform: translate(-50%, 0%) rotate(45deg);
    transform: translate(-50%, 0%) rotate(45deg);
    -webkit-animation: fade_move_down 2s ease-in-out infinite;
    -moz-animation: fade_move_down 2s ease-in-out infinite;
    animation: fade_move_down 2s ease-in-out infinite;
    @media (max-width: 576px) {
      bottom: 3vh;
    }
  }
}