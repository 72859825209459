// Colors
$white: #fff;
$black: #2a2d2e;
$red: #f77373;
$silver: #ccc;

// Sizes
$base-font-size: 16px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1140px;

// Fonts