.call-back {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .8);
  z-index: 13;
  &__inner {
    position: fixed;
    padding: 45px 30px 30px;
    max-width: 600px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
    background: #f5f7f8;
    transition: all .5s ease;
    @media (max-width: 576px) {
      padding: 45px 23px 23px;
      max-width: 310px;
    }
  }
  &__title {
    font-size: 34px;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
  &__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    position: relative;
    i {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }
  textarea {
    padding-top: 11px;
    height: 150px;
  }
  &__input {
    margin-bottom: 25px;
    padding-left: 45px;
    padding-right: 20px;
    width: 100%;
    height: 45px;
    font-size: 14px;
    color: $black;
    text-align: left;
    border: 1px solid #c5c6c6;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
    &::placeholder {
      color: #737373;
      opacity: .7;
    }
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      display: block;
    }
  }
  &__text {
    width: 40%;
    @media (max-width: 576px) {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
    }
  }
  &__submit {
    width: 55%;
    min-height: 45px;
    background: $red;
    font-size: 18px;
    color: $white;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: all .5s ease;
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
      background: #f14141;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  &_active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 42px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 30px;
  }
  &__inner {
    width: inherit;
    text-align: center;
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: inherit;
      background: $red;
      left: 0;
      transition: all .3s ease-in;
    }
    &::before {
      top: 50%; 
      transform: rotate(45deg);  
    }
    &::after {
      bottom: 50%;
      transform: rotate(-45deg);
    }
  }
  &__label {
    margin-bottom: 0;
    font-size: 9px; 
    line-height: 1;
    text-transform: uppercase;
    color: $black;
    transition: all .3s ease-in;
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    .close-btn {
      &__inner {
        &::before,
        &::after {
          transform: rotate(0);
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
        label {
          opacity: 1;
        }
      }
    }
  }
}