footer { 
  flex: 0 0 auto; 
}

.footer {
  padding: 45px 0 20px;
  background-color: #26272b;
  color: #737373;
  font-size: 15px;
  line-height: 24px;
  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
  }
  &__title {
    margin-top: 5px;
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: $white;
  }
  &__separator {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: #bbb;
    opacity: .5;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
  }
  &__socials {
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
  &__services {
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  &__copyright {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.footer-menu {
  &__link {
    position: relative;
    padding-left: 13px;
    color: #737373;
    text-decoration: none;
    outline: none;
    i {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin-right: 5px;
      font-size: 14px;
      color: #737373;
      transition: transform .5s linear;
    }
    &:hover {
      color: $red;
      text-decoration: none;
      i {
        color: $red;
        left: auto;
        right: -20px;
        transform: translateX(10px) translateY(-50%);
      }
    }
  }
}

.social-icons {
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    justify-content: start;
  }
  &__item {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    text-decoration: none;
    color: #737373;
    outline: none;
    border-radius: 100%;
    background-color: #33353d;
    text-align: center;
    transition: all .5s linear;
    i {
      font-size: 14px;
      line-height: 1px;
      color: #818a91;
      transition: all .5s ease;
    }
    &.facebook {
      &:hover {
        background-color: #3b5998;
      }
    }
    &.vk {
      &:hover {
        background-color: #5082b9;
      }
    }
    &.telegram {
      &:hover {
        background-color: #2ba7dd;
      }
    }
    &.viber {
      &:hover {
        background-color: #9069ae;
      }
    }
    &.mail {
      &:hover {
        background-color: $red;
      }
    }
    &:hover {
      text-decoration: none;
      i {
        transform: rotate(360deg);
        color: $white;
      }
    }
  }
}

.developer {
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 576px) {
    margin-top: 5px;
  }
  &__link {
    text-decoration: none;
    color: $red;
    letter-spacing: 1px;
    transition: all .5s ease;
    &:hover {
      color: $red;
      opacity: .7;
    }
  }
}