.uptocall-mini {
  display: none;
  position: fixed;
  bottom: 30px;
  left: 50px;
  cursor: pointer;
  z-index: 11;
  @media (max-width: 480px) {
    display: block;
  }
  i {
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    font-size: 30px;
    transition: transform .3s ease;
  }
  &__phone {
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    border-radius: 50% !important;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: rgba(26, 255, 179, .5);
    width: 72px;
    height: 72px;
    -webkit-animation: uptocallmini 1.5s 0s ease-out infinite;
    -moz-animation: uptocallmini 1.5s 0s ease-out infinite;
    animation: uptocallmini 1.5s 0s ease-out infinite;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url('../img/user.png') no-repeat center center;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0,0,0,.5);
    }
  }
  &:hover, 
  &:focus, 
  &:active {
    i {
      transform: translate3d(-50%, -50%, 1px) rotate(0deg);
    }
  }
  &_form {
    display: block;
    bottom: 30px;
    left: auto;
    right: 50px;
    .uptocall-mini__phone {
      -webkit-animation: uptocallminiform 1.5s 0s ease-out infinite;
      -moz-animation: uptocallminiform 1.5s 0s ease-out infinite;
      animation: uptocallminiform 1.5s 0s ease-out infinite;
      background-color: rgba(247, 115, 115, .5);
    }
  }
}

@-webkit-keyframes uptocallphone {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-moz-keyframes uptocallphone {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes uptocallphone {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes uptocallmini {
  0% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(23, 167, 167, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #1affb3, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px #1affb3;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(23, 167, 167, 0);
  }
}

@-moz-keyframes uptocallmini {
  0% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(23, 167, 167, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #1affb3, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px #1affb3;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(23, 167, 167, 0);
  }
}

@keyframes uptocallmini {
  0% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(23, 167, 167, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #1affb3, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px #1affb3;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(23, 167, 167, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(23, 167, 167, 0);
  }
}















@-webkit-keyframes uptocallminiform {
  0% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(247, 115, 115, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px $red, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px $red;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(247, 115, 115, 0);
  }
}

@-moz-keyframes uptocallminiform {
  0% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(247, 115, 115, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px $red, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px $red;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(247, 115, 115, 0);
  }
}

@keyframes uptocallminiform {
  0% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 0px rgba(0, 0, 0, 0), 0 0 0px 0px rgba(247, 115, 115, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px $red, 0 0 12px 10px rgba(0, 0, 0, 0), 0 0 12px 14px $red;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(247, 115, 115, 0), 0 0 0px 40px rgba(0, 0, 0, 0), 0 0 0px 40px rgba(247, 115, 115, 0);
  }
}