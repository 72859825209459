// START Questions-block section
.questions-block {
  position: relative;
  background: $red;
  z-index: 0;
  &__inner {
    text-align: center;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 30px;
    color: $white;
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
  &__text {
    margin-bottom: 20px;
    font-size: 18px;
    color: $white;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  &__phones {
    display: flex;
    flex-direction: column;
  }
  &__phones {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__number {
    margin-bottom: 5px;
    text-decoration: none;
    outline: none;
    font-size: 18px;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  .btn {
    margin-bottom: 10px;
    min-width: 150px;
    font-size: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}
// END Questions-block section