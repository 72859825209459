@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/animation";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

body {
  position: relative;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

*, *::before, *::after {
  box-sizing: border-box;
}

ul[class],
ol[class],
li {
  list-style: none;
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

audio, canvas, iframe, img, svg, video, textarea {
  vertical-align: middle;
}

.a18622da_e04c_41fc_b6ea_82a9b5417c40_6bd79e10_5bb8_4d3b_a208_ccc01b469bf4_center ~ a {
  display: none !important;
}

.a18622da_e04c_41fc_b6ea_82a9b5417c40_6bd79e10_5bb8_4d3b_a208_ccc01b469bf4_root {
  z-index: 10 !important;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

section {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  @media (max-width: 480px) {
    padding-bottom: 3rem;
  }
}

.section_small {
  padding-top: 3rem;
  padding-bottom: 3rem; 
}

.section_pb-0 {
  padding-bottom: 0;
}

.section_p-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

.content {
  flex: 1 0 auto;
}

.loader {
  background: none repeat scroll 0 0 #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  &__inner {
    background-image: url("../../static/images/content/preloader.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    margin-left: -30px;
    left: 50%;
    top: 50%;
    position: absolute;
  }
}

.header-other {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 400px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, .8);
  }
  .title-box {
    position: relative;
    margin-bottom: 0;
    &__name {
      letter-spacing: 3px;
      color: $white;
      @media (max-width: 520px) {
        letter-spacing: 0;
      }
    }
  }
  &_price {
    background: url('../../static/images/content/prices-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_fasad {
    background: url('../../static/images/content/fasad-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_warming {
    background: url('../../static/images/content/uteplenie-sten.jpg');
    background-repeat: no-repeat;
    background-position: 50% 70%;
    background-size: cover;
  }
  &_snow {
    background: url('../../static/images/content/snow-crisha.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_conditioning {
    background: url('../../static/images/content/services/kondicioner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: cover;
  }
  &_special {
    background: url('../../static/images/content/services/Remont-dachnogo-doma4-1024x760.jpg');
    background-repeat: no-repeat;
    background-position: 50% 13%;
    background-size: cover;
  }
  &_washing {
    background: url('../../static/images/content/window.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_window {
    background: url('../../static/images/content/paket014.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_park {
    background: url('../../static/images/content/gallery/park-2.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }
  &_advertising {
    background: url('../../static/images/content/montaj-reklamnuh.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_cooperation {
    background: url('../../static/images/content/sotrudnichestvo.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &_about {
    background: url('../../static/images/content/header_bg-2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.breadcrumbs {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  &__link {
    text-decoration: none;
    color: #555;
    transition: all .5s ease;
    &:hover {
      color: $red;
      text-decoration: none;
    }
  }
}

.title-box {
  margin-bottom: 5rem;
  width: 100%;
  height: 100%;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
  &__name {
    display: inline-block;
    margin-bottom: 30px;
    font-family: "Playfair Display", serif;
    padding: 0 17px;
    font-size: 37px;
    line-height: .3;
    background: linear-gradient(to right, transparent 50%, $red 50%);
    background-size: 200%;
    background-position: 0;
    color: #2a2d2e;
    cursor: default;
    transition: all 500ms ease;
    &:hover {
      background-position: -100%;
    }
    @media (max-width: 1200px) {
      padding: 0;
      background-position: -100%;
    }
    @media (max-width: 480px) {
      font-size: 30px;
    }
    &_lh {
      @media (max-width: 520px) {
        line-height: 1.3;
        text-transform: none !important;
      }
    }
  }
  &__subtitle {
    margin: 0 auto 30px;
    max-width: 500px;
    font-family: "Playfair Display", serif;
    font-size: 18px;
    color: #95999c;
    text-align: center;
  }
  &__separator {
    margin: 0 auto;
    width: 100px;
    height: 1px;
    background: $red;
  }
  &__link {
    // color: inherit;
    color: #95999c;
    text-decoration: none;
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      padding: 0 2px;
      width: 100%;
      z-index: -1;
      -webkit-transition: height 500ms;
      transition: height 500ms;
      background-color: $red;
      height: 0.50em;
      left: -1px;
      bottom: 1px;
    }
    &:hover::before {
      height: 1.25em;
    }
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  &_white {
    .title-box {
      &__name {
        position: relative;
        color: $white;
      }
      &__subtitle {
        position: relative;
        color: #adabab;
      }
      &__separator {
        position: relative;
      }
    }
  }
}

// START Services section
.services {
  background: #f4f4f4;
}
.services-card {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  outline: none;
  transform: scale(1);
  transition: all 1s cubic-bezier(.25,.8,.25,1);
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 40px 50px;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -9999px -9999px;
    min-height: 740px;
    cursor: pointer;
    position: relative;
    @media (max-width: 576px) {
      padding: 30px;
    }
  }
  &__title {
    margin-bottom: 50px;
    min-height: 36px;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    font-weight: 700;
    color: #424242;
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
  &__list {
    padding-left: 0;
  }
  &__text {
    font-size: 15px;
    line-height: 18px;
    color: #95999c;
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
  &__li {
    margin-bottom: 40px;
    list-style: none;
    overflow: hidden;
    color: #95999c;
    &:last-child {
      margin-bottom: 0;
    }
  }
  i {
    margin-bottom: 10px;
    font-size: 40px;
    color: #95999c;
  }
  @media (max-width: 992px) {
    text-decoration: none;
    .services-card {
      overflow: hidden;
      &__item {
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        position: relative;
        box-shadow: 25px 25px 35px 0 rgba(0, 0, 0, 0.1);
        background-position: 0 0;
        z-index: 1;
        transition: fill 1s linear;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: .8;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      &__text, &__title {
        color: $white;
        z-index: 3;
        position: relative;
      }
    }
    i {
      position: relative;
      color: $white;
      z-index: 3;
    }
  }
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    text-decoration: none;
    transform: scale(1.04);
    z-index: 1;
    .services-card {
      overflow: hidden;
      &__item {
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        position: relative;
        box-shadow: 25px 25px 35px 0 rgba(0, 0, 0, 0.1);
        background-position: 0 0;
        z-index: 1;
        transition: fill 1s linear;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: .8;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      &__text, &__title {
        color: $white;
        z-index: 3;
        position: relative;
      }
    }
    i {
      position: relative;
      color: $white;
      z-index: 3;
    }
  }
  &_red {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/img_1009.jpg);
            background-position: 75%;
          }
          &::before {
            background: $red;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/img_1009.jpg);
            background-position: 75%;
          }
          &::before {
            background: $red;
          }
        }
      }
    }
  }
  &_blue-light {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/raboty-na-vysote.jpg);
          }
          &::before {
            background: rgba(158, 209, 232, 0.87);
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/raboty-na-vysote.jpg);
          }
          &::before {
            background: rgba(158, 209, 232, 0.87);
          }
        }
      }
    }
  }
  &_purple {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/Remont-dachnogo-doma4-1024x760.jpg);
            background-position: 20%;
          }
          &::before {
            background: #3d0d54;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/Remont-dachnogo-doma4-1024x760.jpg);
            background-position: 20%;
          }
          &::before {
            background: #3d0d54;
          }
        }
      }
    }
  }
  &_yellow {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/window.jpg);
            background-position: 10%;
          }
          &::before {
            background: #ffd333;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/window.jpg);
            background-position: 10%;
          }
          &::before {
            background: #ffd333;
          }
        }
      }
    }
  }
  &_purple-light {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/sneg.jpg);
          }
          &::before {
            background: #8e9dca;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/sneg.jpg);
          }
          &::before {
            background: #8e9dca;
          }
        }
      }
    }
  }
  &_silver {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/kondicioner.jpg);
            background-position: 75%;
          }
          &::before {
            background: #808080;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/kondicioner.jpg);
            background-position: 75%;
          }
          &::before {
            background: #808080;
          }
        }
      }
    }
  }
  &_blue {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/steklopaket2.jpg);
            background-position: 75%;
          }
          &::before {
            background: #007bff;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/steklopaket2.jpg);
          }
          &::before {
            background: #007bff;
          }
        }
      }
    }
  }  
  &_green {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/gallery/park.jpg);
            background-position: 75%;
          }
          &::before {
            background: #50de3f;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/gallery/park.jpg);
          }
          &::before {
            background: #50de3f;
          }
        }
      }
    }
  }  
  &_pink {
    &:hover {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/7187.jpg);
            background-position: 75%;
          }
          &::before {
            background: #dc5cc0;
          }
        }
      }
    }
    @media (max-width: 992px) {
      .services-card {
        &__item {
          &::after {
            background-image: url(../../static/images/content/services/7187.jpg);
          }
          &::before {
            background: #dc5cc0;
          }
        }
      }
    }
  }
}
// END Services section

// START Gallery section
  .gallery {
    figure {
      position: relative;
      margin: 0 0 1.5rem;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.5);
          filter: opacity(100%);
        }
      }
      a {
        @media (max-width: 575px) {
          width: 100%;
          height: 100%;
        }
      }
    }
    img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      border-style: none;
      filter: opacity(70%);
      transition: transform 3s ease,
                  filter .5s ease;
      @media (max-width: 575px) {
        min-height: 100%;
      }
    }
    &__first-column {
      figure {
        &:last-child {
          img {
            width: 100%;
            height: 432px;
            @media (max-width: 1200px) {
              height: 363px;
            }            
            @media (max-width: 992px) {
              height: 269px;
            }
            @media (max-width: 768px) {
              height: 199px;
            }
            @media (max-width: 575px) {
              height: auto;
            }
          }
        }
      }
    }
    &__second-column {
      figure {
        &:last-child {
          img {
            width: 100%;
            height: 329px;
            @media (max-width: 1200px) {
              height: 259px;
            }
            @media (max-width: 1199px) {
              height: 268px;
            }
            @media (max-width: 992px) {
              height: 187px;
            }
            @media (max-width: 768px) {
              height: 128px;
            }
            @media (max-width: 575px) {
              height: auto;
            }
          }
        }
      }
    }
    &__third-column {
      figure {
        &:last-child {
          img {
            width: 100%;
            height: 406px;
            @media (max-width: 1200px) {
              height: 336px;
            }
            @media (max-width: 992px) {
              height: 243px;
            }
            @media (max-width: 768px) {
              height: 175px;
            }
            @media (max-width: 575px) {
              height: auto;
            }
          }
        }
        @media (max-width: 575px) {
          height: 220px;
        }
      }
    }
  }
// END Gallery section

// START Why-we section
.why-we {
  position: relative;
  background: url(../../static/images/content/why-we-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  &__card {
    cursor: pointer;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    &:hover {
      i {
        transform: translateY(-10px);
      }
      .why-we__number {
        color: $red;
        transform: scale(1.3);
      }
      .why-we__text {
        transform: translateY(10px);
      }
    }
  }
  i {
    margin-bottom: 25px;
    font-size: 60px;
    color: $white;
    transition: transform 1.5s ease;
  }
  &__number {
    margin-bottom: 17px;
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    color: $white;
    transform: scale(1);
    transition: all .5s ease;
  }
  &__text {
    font-size: 16px;
    line-height: 1;
    color: $white;
    transition: transform 1.5s ease;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    opacity: .8;
  }
}
// END Why-we section

// START Advantages section
.advantages {
  position: relative;
  background: url(../../static/images/content/rabotanavisote.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  &__list {
    position: relative;
  }
  &__item {
    margin-bottom: 80px;
    max-width: 300px;
    cursor: pointer;
    transition: all .5s ease;
    @media (max-width: 768px) {
      max-width: 220px;
      text-align: center;
    }
    @media (max-width: 576px) {
      margin: 0 auto 80px;
      max-width: 320px;
    }
    &:hover {
      .advantages__title {
        color: $red;
      }
      i {
        box-shadow: 0px 2px 10px $red;
      }
    }
  }
  .col-md-5:nth-child(4), 
  .col-md-5:nth-child(5) {
    i {
      padding: 23px 20px;
    }
  }
  i {
    margin-bottom: 10px;
    padding: 20px;
    font-size: 65px;
    box-shadow: 0px 2px 0px rgba(250, 66, 97, 0);
    border-radius: 50%;
    color: $white;
    transition: all .5s ease;
    @media (max-width: 992px) {
      box-shadow: 0px 2px 10px $red;
    }
  }
  &__title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    color: $white;
    transition: all .5s ease;
    @media (max-width: 992px) {
      color: $red;
    }
  }
  &__text {
    font-size: 14px;
    color: $white;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    opacity: .8;
  }
}
// END Advantages section

// START About section
.about {
  &__left-column {
    position: relative;
    height: 100%;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -5px;
      transform: translateY(-50%);
      height: 90%;
      width: 5px;
      background-color: $red;
      @media (max-width: 767px) {
        top: auto;
        bottom: -5px;
        width: 90%;
        height: 5px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @media (max-width: 767px) {
      height: 200px;
    }
  }
  &__right-column {
    display: flex;
    align-items: center;
    padding: 0 50px;
    height: 100%;
    @media (max-width: 992px) {
      padding: 0 20px;
    }
    @media (max-width: 767px) {
      display: block;
      margin-top: 30px; 
      padding: 0 15px;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__description {
    max-width: 540px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  &__title {
    position: relative;
    margin-bottom: 20px;
    font-size: 22px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 88px;
      height: 2px;
      border-radius: 1px;
      background-color: $red;
    }
    span {
      color: $red;
    }
  }
  &__text {
    margin-bottom: 30px;
    color: #95999c;
    @media (max-width: 992px) {
      margin-bottom: 15px; 
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .container-fluid {
    padding: 0;
  }
  .btn {
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
// END About section

// START Contacts section
  .contacts {
    background: #fbfbfb;
    text-align: center;
    &__map {
      position: relative;
      width: 100%;
      height: 500px;
      background: #333;
      iframe {
        background: #333;
        opacity: .5;
      }
    }
    &__form {
      position: absolute;
      top: 50%;
      right: 50px;
      transform: translateY(-50%);
      padding: 30px 40px;
      width: 380px;
      min-height: 305px;
      background: #fff;
      border-radius: 20px;
      @media (max-width: 576px) {
        padding: 20px;
        right: 20px;
        width: 275px;
      }
      @media (max-width: 480px) {
        right: auto;
        left: 50%;
        transform: translate3d(-50%, -50%, 1px);
      }
    }
    &__list {
      text-align: left;
    }
    &__title {
      margin-bottom: 18px;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
    }
    &__item {
      position: relative;
      margin-bottom: 15px;
      padding-left: 30px;
    }
    i {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .container-fluid {
      padding: 0;
    }
    .btn {
      color: #333;
    }
  }
// END Contacts section

// START Call section
.call {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #272d33;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
  &__left-column {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    color: $white;
  }
  &__right-column {
    position: relative;
    width: 50%;
    height: 100%;
    color: $black;
    @media (max-width: 992px) {
      width: 45%;
    }
    @media (max-width: 576px) {
      position: absolute;
      right: 5%;
    }
    &::after {
      content: '';
      background-color: $red;
      position: absolute;
      top: 0;
      right: 0;
      transform: skewX(-17deg);
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
  &__list {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $white;
    cursor: default;
    z-index: 1;
    &:hover {
      i {
        transform: rotate(320deg);
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
  }
  &__text {
    font-size: 22px;
    text-decoration: none;
    color: #fff;
    outline: none;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
    @media (max-width: 992px) {
      font-size: 18px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  i {
    margin-right: 10px;
  }
}
// END Call section

// START Reviews section
.reviews {
  background: #fbfbfb;
  &-slider {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    &__logo {
      margin: 0 auto 30px;
      max-width: 130px;
      max-height: 130px;
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 
                  0 6px 20px 0 rgba(0, 0, 0, 0.19);
      @media (max-width: 576px) {
        max-width: 90px;
        max-height: 90px;
      }
    }
    &__title,
    &__text {
      margin: 15px 0 0;
      padding: 0;
    }
    &__title {
      font-size: 20px;
      color: #333;
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    &__text {
      font-size: 16x;
      color: #333;
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .slick {
    &-slider {
      button {
        display: none !important;
      }
    }
    &-slide {
      padding: 25px 0;
      font-size: 1.25em;
      text-align: center;
      color: #333;
      pointer-events: none;
      opacity: .2;
      transition: all 300ms ease;
      .reviews-slider__description {
        opacity: 0;
      }
      .reviews-slider__description > * {
        transition: all 900ms ease;
      }
      .reviews-slider__title {
        position: relative;
        left: 50px;
        line-height: 1;
      }
      .reviews-slider__text {
        position: relative;
        top: 50px;
        opacity: 0;
        color: #95999c;
      }
      .reviews-slider__logo {
        width: 100%;
      }
    }
    &-prev,
    &-next {
      &::before {
        color: black;
      }
    }
    &-dots {
      text-align: center;
      li {
        display: inline-block;
        padding: 1px;
        background: #ccc;
        margin: 10px 5px;
        width: 40px;
        height: 5px;
        &.slick-active {
          background: $red;
        }
      }
      button {
        display: none;
      }
    }
    &-current {
      opacity: 1;
      transform: scale(1.1);
      .reviews-slider__description {
        opacity: 1;
      }
      .reviews-slider__title {
        left: 0;
      }
      .reviews-slider__text {
        top: 0;
        opacity: 1;
      }
    }
  }
}
// END Reviews section

// START Clientage section
.clientage {
  .slick {
    &-slide {
      margin: 0px 20px;
      outline: none;
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
      img {
        display: block;
        width: 100%;
        padding: 15px;
        outline: none;
      }
      .slick-loading {
        img {
          display: none;
        }
      }
      .dragging {
        img {
          pointer-events: none;
        }
      }
    }
    &-initialized {
      .slick-slide {
        display: block;
      }
    }
    &-slider &-track,
    &-slider &-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    &-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    &-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      &:focus {
        outline: none;
      }
      .dragging {
        cursor: pointer;
        cursor: hand;
      }
    }
    &-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      &:after {
        clear: both;
      }
      &:before,
      &:after {
        display: table;
        content: '';
      }
    }
    &-loading &-track {
      visibility: hidden;
    }
    [dir='rtl'] .slick-slide {
      float: right;
    }
    &-loading {
      .slick-slide {
        visibility: hidden;
      }
    }
    &-vertical {
      .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
      }
    }
    &-arrow {
      .slick-hidden {
        display: none;
      }
    }
  }

  .slider {
    &:hover {
      .slide {
        filter: blur(0px);
        -webkit-filter: blur(0px);
        &:not(:hover) {
          filter: blur(2px);
          -webkit-filter: blur(2px);
        }
      }
    }
  }

  .slide {
    list-style: none;
    margin: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    img {
      height: 5rem;
      -webkit-filter: grayscale(100%) brightness(10%) contrast(10%);
      filter: grayscale(100%) brightness(10%) contrast(10%); 
      cursor: pointer;
      &:hover {
        background: none;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; 
      }
    }
    &:hover {
      -webkit-transform: translateY(-0.3rem);
      transform: translateY(-0.3rem); 
    }
  }
}
// END Clientage section

// START style for other pages
.page {
  padding-top: 0 !important;
  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
  &__text {
    margin-bottom: 20px;
    &_big {
      font-size: 25px;
      text-transform: uppercase;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
  }
  &__title-table {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    color: #333;
  }
  &__table {
    @media (max-width: 767px) {
      white-space: nowrap;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        background-color: $silver;
        width: 10px;
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $red;
      }
    }
  }
  &__btn {
    margin-top: 4rem;
    text-align: center;
    @media (max-width: 480px) {
      margin-top: 2rem;
    }
  }
  &__minimal {
    text-align: center;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
  &__list {
    padding-left: 30px !important;
    margin-bottom: 20px !important;
    @media (max-width: 576px) {
      padding-left: 20px !important;
    }
  }
  &__item {
    margin-bottom: 5px;
    list-style-type: disc;
  }
  &__figure {
    margin-bottom: 30px;
  }
  &__img-txt {
    margin-top: 20px;
    font-weight: 700;
    color: $red;
  }
  &__block {
    margin: 0 auto 20px;
    padding: 1rem;
    max-width: 740px;
    background: #f9f9f9;
    border-left: 10px solid $red;
    .page__text {
      margin-bottom: 0;
    }
  }
}

.table {
  border: 1px solid #ccc;
  text-align: left;
  width: 100%;
  border-left: 0;
  border-bottom: 0;
  border-radius: 0;
  td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    border-top: 0;
    border-right: 0;
    &:first-child {
      width: 65%;
    }
  }
  &__gray-bg {
    background: #454d55 !important;
    color: $white;
  }
  &__red-bg {
    background: $red !important;
    color: $white;
  }
  a {
    text-decoration: none;
  }
}
// END style for other pages

.questions {
  margin: 0 auto;
  background: #fbfbfb;
  &__list {
    display: flex;
    flex-direction: column;
    max-width: 740px;
    margin: 0 auto;
  }
  &__item {
    background-color: #fff;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
      .questions__title {
        color: $red;
      }
      .questions__plus {
        color: $red;
        border-color: $red;
      }
    }
    &_active {
      .questions__plus {
        &::before {
          content: "-";
        }
      }
      .questions__head {
        border-color: $red;
      }
      .questions__title {
        color: $red;
      }
      .questions__plus {
        color: $red;
        border-color: $red;
      }
      .questions__body {
        display: block;
        opacity: 1;
      }
    }
  }
  &__title {
    font-size: 20px;
  }
  &__plus {
    position: relative;
    font-size: 20px;
    border: 1px solid $black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    &::before {
      content: "+";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 1px);
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    padding: 1rem;
  }
  &__body {
    display: none;
    padding: 1rem;
  }
  &__text {
    font-size: 16px;
  }
}

//Модули
@import "modules/modules";
