.btn {
	position: relative;
	background: none;
	border: none;
	outline: none;
	padding: 10px 15px 7px 15px;
	font-weight: 700;
	font-size: 14px;
  letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	color: black;
	cursor: pointer;
	&::after {
		@include transform(translateY(-50%));
		content: "";
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: $red;
		position: absolute;
		left: -5px;
		top: 50%;
		z-index: -1;
		transition: all .5s ease;
	}
	&:focus {
		outline: none;
		box-shadow: none;
	}
	&:hover,
	&:focus {
    color: $white;
		opacity: 1;
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 40px;
    }
  }
	&_black {
		color: $white;
		&::after {
			background-color: #26272bad;
		}
		&:hover {
			color: $white;
		}
	}
	@media (max-width: 992px) {
		color: $white !important;
		opacity: 1;
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 40px;
    }
	}
}